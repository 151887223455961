
import React, {  useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
//import './Page3.css';//each page css
import Noticecss from './Notice.module.css';
import * as DOMPurify from 'dompurify';
import { List, Icon, Grid, Menu, Sidebar } from 'semantic-ui-react';
const Notice = () =>{

    const [Open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const [LanguageSelected, setLanguageSelected] = useState(i18n.language);
    const navigate = useNavigate();

    const sanitized = DOMPurify.sanitize(t('Notice_body'));
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        document.title = t('Page_Title');
    };

    useEffect(() => {
        document.title = t('Page_Title');
        window.addEventListener('keydown', function (event) {
            const key = event.key; // const {key} = event; in ES6+
            if (key === "Escape") {
                setOpen(false)
            }

        });

    }, []);


    return (
        <>
            <h1 className="hidden">{t('Page_Title')}</h1>
            <div >
                <Grid.Column>
                    <Sidebar.Pushable>
                        <Sidebar
                            as={Menu}
                            animation='overlay'
                            icon='labeled'

                            onHide={() => setOpen(false)}
                            vertical
                            visible={Open}
                            width='thin'
                        >
                            <Menu.Item as='a' >
                                <List divided relaxed size='big'>
                                    <List.Item><img src={require('./image/logo.png')} alt="HAD LOGO"></img></List.Item>
                                    <List.Item onClick={() => navigate('/')}> {t('Home')} </List.Item>
                                    <List.Item onClick={() => navigate('/Search', { state: { search: "None" } })}>{t('Search')}</List.Item>
                                    <List.Item onClick={() => navigate('/result', { state: { search: "Full" } })}>{t('ListAll')}</List.Item>
                                    <List.Item onClick={() => navigate('/help')}>{t('Help')}</List.Item>
                                    <List.Item  onClick={() => { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") }} alt="zh-cn">简体</List.Item>
                                    <List.Item  onClick={() => { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") }} alt="zh-tw">繁體</List.Item>
                                    <List.Item  onClick={() => { changeLanguage("en-US"); setLanguageSelected("en-US") }} alt="English">English</List.Item>
                                </List>
                            </Menu.Item>
                        </Sidebar>
                        <Sidebar
                            as={List}
                            animation='overlay'
                            icon='labeled'
                            direction='right'
                            onHide={() => setOpen(false)}
                            vertical
                            visible={Open}
                            width='thin'
                        >
                            <List.Item divided relaxed size='massive' className={Noticecss.closebutton} >
                                X
                            </List.Item>
                        </Sidebar>

                        <Sidebar.Pusher dimmed={Open}>
                            <div className={Noticecss.container} >
                                <div className={Noticecss.responsive}><Icon size='big' name='bars' className={Noticecss.responsiveLogo} onClick={() => setOpen(true)}> </Icon></div>
                                <div className={Noticecss.title}>
                                    <div>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") } }} className={LanguageSelected === "zh-CN" ? Noticecss.i18nSelected : Noticecss.i18n} onClick={() => { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") }} alt="zh-cn">简体</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") } }} className={LanguageSelected === "zh-TW" ? Noticecss.i18nSelected : Noticecss.i18n} onClick={() => { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") }} alt="zh-tw">繁體</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("en-US"); setLanguageSelected("en-US") } }} className={LanguageSelected === "en-US" ? Noticecss.i18nSelected : Noticecss.i18n} onClick={() => { changeLanguage("en-US"); setLanguageSelected("en-US") }} alt="English">English</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/text') } }} className={Noticecss.textsize} onClick={() => navigate('/text')}> {t('Text')}</span>
                                    </div>
                                </div>
                                <div className={Noticecss.logo} >
                                    <img style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} src={require('./image/logo.png')} className={Noticecss.HADLOGO} onClick={() => navigate('/')} alt="HAD LOGO"></img>
                                    <img style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} src={require('./image/title_' + i18n.language + '.png')} className={Noticecss.HADLOGO_title} onClick={() => navigate('/')} alt="HAD LOGO"></img>
                                </div>
                                <div className={Noticecss.menu}>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} onClick={() => navigate('/')} ><Icon name='home' />{t('Home')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/Search', { state: { search: "None" } }) } }} onClick={() => navigate('/Search', { state: { search: "None" } })} ><Icon name='search' />{t('Search')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/result', { state: { search: "Full" } }) } }} onClick={() => navigate('/result', { state: { search: "Full" } })} ><Icon name='list' />{t('ListAll')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/help') } }} onClick={() => navigate('/help')} ><Icon name='question' />{t('Help')}</label>
                                </div>
                                <div className={Noticecss.main}>
                                    <div className={Noticecss.mainframe}>
                                        <div className={Noticecss.mainframeTitle} >{t('Notice_title')}</div>
                                        <div dangerouslySetInnerHTML={{ __html: sanitized }} className={Noticecss.mainframeContent} />
                                    </div>
                                    <br />
                                    <button className={Noticecss.backpage} onKeyDown={(event) => { if (event.key === "Enter") { navigate(-1) } }} onClick={() => navigate(-1) } >{t('Back')}</button>
                                </div>
                                <div className={Noticecss.footer}>
                                    <div className={Noticecss.footerLeftLabel} tabindex="0" ><a href="https://www.w3.org/WAI/WCAG2AA-Conformance"
                                        title="Explanation of WCAG 2 Level AA conformance">
                                        <img height="32" width="88"
                                            src="https://www.w3.org/WAI/WCAG21/wcag2.1AA-v"
                                            alt="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1" />
                                    </a></div>
                                    <div style={{ 'cursor': 'pointer' }} className={Noticecss.footerLeftLabel} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/notice') } }} onClick={() => navigate('/notice')} >{t('Notice')}</div>
                                    <div style={{ 'cursor': 'pointer' }} className={Noticecss.footerLeftLabel} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/policy') } }} onClick={() => navigate('/policy')} >{t('Policy')}</div>
                                    <div className={Noticecss.footerRightLabel} tabindex="0" >{t('Copyright_f')} {(new Date().getFullYear())} {t('Copyright_b')}</div>
                                </div>
                            </div>

                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Grid.Column>

            </div>

        </>
    );
}

export default Notice;