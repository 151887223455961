import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, Suspense } from 'react';
import Collapsible from 'react-collapsible';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import Resultcss from './Result.module.css';
import { Pagination, Icon, List, Grid, Menu, Sidebar } from 'semantic-ui-react'




const schema = yup
    .object()
    .shape({
        District: yup.string().nullable(),
        Building_Name: yup.string().nullable(),
        Estate: yup.string().nullable(),
        Street: yup.string().nullable(),
        Village: yup.string().nullable(),
        StreetNo: yup.string().nullable(),
        Storeys: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        Units: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        Yearfrom: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        Yearto: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        OC: yup.bool(),
        OComm: yup.bool(),
        PMC: yup.bool(),
        Others: yup.bool(),
        Without: yup.bool()

    })
    .required();

function removeEmptyFields(data) {
    Object.keys(data).forEach(key => {
        if (data[key] === '' || data[key] == null || data[key] === false) {
            delete data[key];
        }
    });
}




const Result = () => {
    const [ResponseData, setResponseData] = useState(null);
    const [SearchKey, setSearchKey] = useState(null);
    const [SearchPage, setSearchPage] = useState(1);
    const [SearchPageSize, setSearchPageSize] = useState(5);
    const [SortKey, setSortKey] = useState("building_id");
    const [SortDirection, setSortDirection] = useState(1);
    const [Open, setOpen] = useState(false);
    const [TotalPage, setTotalPage] = useState(null);
    const [TotalRecord, setTotalRecord] = useState(null);
    const [isTrigger, setTrigger] = useState(false);
    const navigate = useNavigate();
    const params = useLocation();
    const { t, i18n } = useTranslation();
    const [LanguageSelected, setLanguageSelected] = useState(i18n.language);
    const [width, setwidth] = useState(window.innerWidth);

    const {
        register,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data, e) => {
        setTrigger(false);
        Object.assign(data, { "search": "Condition" });
        removeEmptyFields(data);
        console.log(data, e);
        CallDB(data, 1, SearchPageSize, SortKey, SortDirection);
    }
    const onError = (errors, e) => console.log(errors, e)

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        document.title = t('Page_Title');
        CallDB(SearchKey, SearchPage, SearchPageSize, SortKey, SortDirection);
    };

    function handleResize() {
        setwidth(window.innerWidth);
    }
    //const handleUnload = (event) => {
    //    event.preventDefault();
    //    return '';
    //}
    useEffect(() => {
        document.title = t('Page_Title');
        window.addEventListener('resize', handleResize);
        //window.addEventListener('beforeunload', handleUnload);


        console.log("Onload");
        console.log(SearchKey);
        console.log(params.state);
        if (SearchKey !== null) {
            CallDB(SearchKey, SearchPage, SearchPageSize, SortKey, SortDirection);
        } else if (params.state !== null) {
            CallDB(params.state, 1, SearchPageSize, SortKey, SortDirection);
        }

        window.addEventListener('keydown', function (event) {
            const key = event.key; // const {key} = event; in ES6+
            if (key === "Escape") {
                setOpen(false)
            }

        });

    }, []);

    function CallDB(SearchKey, Page, PageSize, Sort, Direction) {
        try {
            setResponseData(null);
            //console.log(Page);
            if (Page <= 0) { Page = 1; } //ignore page before first page
            var raw = JSON.stringify({
                "Case": SearchKey,
                "Page": Page,
                "PageSize": PageSize,
                "Sort": Sort,
                "Direction": Direction,
                "Language": i18n.language
            });

            //console.log(raw);
            fetch('/public_backend/search/', {
                method: 'POST',
                body: raw,
                headers: {
                    'Content-type': 'text/plain; charset=utf-8',
                },
            }).then(async response => {
                const data = await response.json();
                /*//console.log(data);*/
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.message) || response.statusText;

                    return Promise.reject(error);
                } else {
                    //only success request will store 
                    setSearchKey(SearchKey);
                    setSearchPage(Page);
                    setSearchPageSize(PageSize);
                    setSortKey(Sort);
                    setSortDirection(Direction);
                    setTotalPage(data[0].TotalPage);
                    setTotalRecord(data[0].TotalRecord);
                    setResponseData(data[1]);
                    navigate('/result', { state: SearchKey });

                }
            })
                .catch(error => console.error("ERROR:" + error));
        }
        finally {
            console.log("Done!");
        };
    };

    function Sort(key) {
        if (key === SortKey) {
            console.log("Same key");
            CallDB(SearchKey, 1, SearchPageSize, key, -SortDirection);
        } else {
            console.log("Diff key");
            CallDB(SearchKey, 1, SearchPageSize, key, 1);
        }
    }
    function check(e) {
        if (e.target.name === "Without" && !getValues("Without")) {
            setValue("OC", false);
            setValue("OComm", false);
            setValue("PMC", false);
            setValue("Others", false);
        } else if (e.target.name !== "Without" && getValues("Without")) {
            setValue("Without", false);
        }
    }



    const handlePaginationChange = (e, { activePage }) => CallDB(SearchKey, activePage, SearchPageSize, SortKey, SortDirection)


    const DistrictVal = ["", "CENTRAL_AND_WESTERN", "WAN_CHAI", "EASTERN", "SOUTHERN", "YAU_TSIM_MONG", "SHAM_SHUI_PO", "KOWLOON_CITY", "WONG_TAI_SIN", "KWUN_TONG", "KWAI_TSING", "TSUEN_WAN", "TUEN_MUN", "YUEN_LONG", "NORTH", "TAI_PO", "SHA_TIN", "SAI_KUNG", "ISLANDS"];

    return (
        <>
            <h1 className="hidden">{t('Page_Title')}</h1>
            <div >
                <Grid.Column>
                    <Sidebar.Pushable>
                        <Sidebar
                            as={Menu}
                            animation='overlay'
                            icon='labeled'

                            onHide={() => setOpen(false)}
                            vertical
                            visible={Open}
                            width='thin'
                        >
                            <Menu.Item as='a' >
                                <List divided relaxed size='big'>
                                    <List.Item><img src={require('./image/logo.png')} alt="HAD LOGO"></img></List.Item>
                                    <List.Item onClick={() => navigate('/')}> {t('Home')} </List.Item>
                                    <List.Item onClick={() => navigate('/Search', { state: { search: "None" } })}>{t('Search')}</List.Item>
                                    <List.Item onClick={() => navigate('/result', { state: { search: "Full" } })}>{t('ListAll')}</List.Item>
                                    <List.Item onClick={() => navigate('/help')}>{t('Help')}</List.Item>
                                    <List.Item></List.Item>
                                    <List.Item onClick={() => { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") }} alt="zh-cn">简体</List.Item>
                                    <List.Item onClick={() => { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") }} alt="zh-tw">繁體</List.Item>
                                    <List.Item onClick={() => { changeLanguage("en-US"); setLanguageSelected("en-US") }} alt="English">English</List.Item>
                                </List>
                            </Menu.Item>
                        </Sidebar>
                        <Sidebar
                            as={List}
                            animation='overlay'
                            icon='labeled'
                            direction='right'
                            onHide={() => setOpen(false)}
                            vertical
                            visible={Open}
                            width='thin'
                        >
                            <List.Item divided relaxed size='massive' className={Resultcss.closebutton} >
                                X
                            </List.Item>
                        </Sidebar>

                        <Sidebar.Pusher dimmed={Open}>
                            <div className={Resultcss.container} >
                                <div className={Resultcss.responsive} ><Icon size='big' name='bars' className={Resultcss.responsiveLogo} onClick={() => setOpen(true)}> </Icon></div>
                                <div className={Resultcss.title}>
                                    <div>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") } }} className={LanguageSelected === "zh-CN" ? Resultcss.i18nSelected : Resultcss.i18n} onClick={() => { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") }} alt="zh-cn">简体</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") } }} className={LanguageSelected === "zh-TW" ? Resultcss.i18nSelected : Resultcss.i18n} onClick={() => { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") }} alt="zh-tw">繁體</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("en-US"); setLanguageSelected("en-US") } }} className={LanguageSelected === "en-US" ? Resultcss.i18nSelected : Resultcss.i18n} onClick={() => { changeLanguage("en-US"); setLanguageSelected("en-US") }} alt="English">English</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/text') } }} className={Resultcss.textsize} onClick={() => navigate('/text')}> {t('Text')}</span>
                                    </div>
                                </div>
                                <div className={Resultcss.logo} >
                                    <img style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} src={require('./image/logo.png')} className={Resultcss.HADLOGO} onClick={() => navigate('/')} alt="HAD LOGO"></img>
                                    <img style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} src={require('./image/title_' + i18n.language + '.png')} className={Resultcss.HADLOGO_title} onClick={() => navigate('/')} alt="HAD LOGO"></img>
                                </div>
                                <div className={Resultcss.menu}>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} onClick={() => navigate('/')} ><Icon name='home' />{t('Home')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/Search', { state: { search: "None" } }) } }} onClick={() => navigate('/Search', { state: { search: "None" } })} ><Icon name='search' />{t('Search')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/result', { state: { search: "Full" } }) } }} onClick={() => navigate('/result', { state: { search: "Full" } })} ><Icon name='list' />{t('ListAll')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/help') } }} onClick={() => navigate('/help')} ><Icon name='question' />{t('Help')}</label>
                                </div>
                                <div className={Resultcss.main}>
                                    <div className={Resultcss.collapseItem}>
                                        <div className={Resultcss.collapseTrigger} onClick={() => setTrigger(!isTrigger)} > {!isTrigger ? <Icon name="plus"></Icon> : <Icon name="minus"></Icon>}{t('Search')}</div>
                                        <Collapsible trigger="" open={isTrigger} triggerElementProps={{ 'aria-label': 'Collapsible' }} >
                                            <form onSubmit={handleSubmit(onSubmit, onError)} className={Resultcss.form} >
                                                <div className={Resultcss.box}>
                                                    <div className={Resultcss.label}> <label>{t('District')}</label> </div>
                                                    <div >  <select className={Resultcss.selector} name="section" {...register('District')} aria-label="District Selectot">
                                                        {DistrictVal.map((District) =>
                                                            <option value={District} key={District} aria-label="District selector" selected={params.state && params.state.District === District}>{t(District || 'All_District')}</option>
                                                        )}
                                                    </select> </div>
                                                </div>
                                                <div className={Resultcss.box}>
                                                    <div className={Resultcss.label}> <label>{t('Building_Name')}</label> </div>
                                                    <div> <input aria-label="Building Name input" className={Resultcss.textbox} {...register('Building_Name')} defaultValue={params.state.Building_Name} /> </div>
                                                </div>
                                                <div className={Resultcss.box}>
                                                    <div className={Resultcss.label}> <label>{t('Estate')}</label> </div>
                                                    <div> <input className={Resultcss.textbox} aria-label="Estate Input" {...register('Estate')} defaultValue={params.state.Estate} /> </div>
                                                </div>
                                                <div className={Resultcss.box}>
                                                    <div className={Resultcss.label}> <label>{t('Street')}</label></div>
                                                    <div> <input className={Resultcss.textbox} aria-label="Street Input" {...register('Street')} defaultValue={params.state.Street} /> </div>
                                                </div>
                                                <div className={Resultcss.box}>
                                                    <div className={Resultcss.label}> <label>{t('Village')}</label> </div>
                                                    <div> <input className={Resultcss.textbox} aria-label="Village Input" {...register('Village')} defaultValue={params.state.Village} /> </div>
                                                </div>
                                                <div className={Resultcss.box}>
                                                    <div className={Resultcss.label}> <label>{t('StreetNo')}</label> </div>
                                                    <div> <input className={Resultcss.textbox} aria-label="Street No Input" {...register('StreetNo')} defaultValue={params.state.StreetNo} /> </div>
                                                </div>
                                                <div className={Resultcss.box}>
                                                    <div className={Resultcss.label}> <label>{t('Storeys')}</label> </div>
                                                    <div> <input type="number" min="0" className={Resultcss.textbox} aria-label="Storeys Input" {...register('Storeys')} defaultValue={params.state.Storeys} /> </div>
                                                </div>
                                                <div className={Resultcss.box}>
                                                    <div className={Resultcss.label}> <label>{t('Units')}</label> </div>
                                                    <div> <input type="number" min="0" className={Resultcss.textbox} aria-label="Units Input" {...register('Units')} defaultValue={params.state.Units} /> </div>
                                                </div>
                                                <div className={Resultcss.box}>
                                                    <div className={Resultcss.label}> <label>{t('Yearfrom')}</label> </div>
                                                    <div> <input type="number" min="0" className={Resultcss.textbox} aria-label="Year from Input" {...register('Yearfrom')} defaultValue={params.state.Yearfrom} /> </div>
                                                </div>
                                                <div className={Resultcss.box}>
                                                    <div className={Resultcss.label}> <label>{t('Yearto')}</label> </div>
                                                    <div> <input type="number" min="0" className={Resultcss.textbox} aria-label="Year to Input" {...register('Yearto')} defaultValue={params.state.Yearto} /> </div>
                                                </div>

                                                <div className={Resultcss.fieldset}>
                                                    <label >{t('Organization_Type')}</label><br></br>
                                                    <div className={Resultcss.alignSet}>
                                                        <input {...register('OC')} type='checkbox' className={Resultcss.SearchCheckbox} onChange={check} id='OC' aria-label="OC check box" defaultChecked={params.state.OC} />&nbsp;
                                                        <label >{t('OC')}</label>
                                                    </div>
                                                    <div className={Resultcss.alignSet}>
                                                        <input {...register('OComm')} type='checkbox' className={Resultcss.SearchCheckbox} onChange={check} id='OComm' aria-label="OComm check box" defaultChecked={params.state.OComm} />&nbsp;
                                                        <label >{t('OComm')}</label>
                                                    </div>
                                                    <div className={Resultcss.alignSet}>
                                                        <input {...register('PMC')} type='checkbox' className={Resultcss.SearchCheckbox} onChange={check} id='PMC' aria-label="PMC check box" defaultChecked={params.state.PMC} />&nbsp;
                                                        <label >{t('PMC')}</label>
                                                    </div>
                                                    <div className={Resultcss.alignSet}>
                                                        <input {...register('Others')} type='checkbox' className={Resultcss.SearchCheckbox} onChange={check} id='Others' aria-label="Others check box" defaultChecked={params.state.Others} />&nbsp;
                                                        <label >{t('Others')}</label>
                                                    </div>
                                                    <div className={Resultcss.alignSet}>
                                                        <input {...register('Without')} type='checkbox' className={Resultcss.SearchCheckbox} onChange={check} id='Without' aria-label="Without check box" defaultChecked={params.state.Without} />&nbsp;
                                                        <label >{t('Without')}</label>
                                                    </div>
                                                </div>

                                                <input type="submit" value={t('Submit')} aria-label="Submit" className={Resultcss.AllButton} />
                                                <input type="reset" value={t('Reset')} aria-label="Reset" className={Resultcss.AllButton} />
                                            </form>
                                        </Collapsible>
                                    </div>
                                <Suspense fallback={<h2>Loading...</h2>}>
                                    <div className={Resultcss.ResultTable}>
                                        <div className={Resultcss.ResultTableTitle}>{t('Building_Search_Result')}</div>
                                        {window.innerWidth >= 700 && (
                                            <table className={Resultcss.table} aria-label="Building info table">
                                                <thead>
                                                    <tr>
                                                        <th className={Resultcss.Name} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { Sort("Building_Name") } }} onClick={() => Sort("Building_Name")} aria-label="Building name">{t('Building_Name')}</th>
                                                        <th className={Resultcss.District} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { Sort("Address") } }} onClick={() => Sort("Address")} aria-label="Address">{t('Address')}</th>
                                                        <th className={Resultcss.Owner} aria-label="Result_OC">{t('Result_OC')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ResponseData && ResponseData.length > 0 && ResponseData.map((item, index) =>
                                                        <tr key={item.building_id}>
                                                            <td className={(index & 1) ? Resultcss.resultName : Resultcss.resultName}><span style={{ 'cursor': 'pointer' }} tabindex="0"  onClick={() => navigate('/info', { state: { search: "Single", building_id: item.building_id } })} onKeyDown={(event) => { if (event.key === "Enter") { navigate('/info', { state: { search: "Single", building_id: item.building_id } }) } }} >
                                                                
                                                            { (item.Building_Name).length > 0 &&
                                                                item.Building_Name
                                                            }

                                                            {item.Building_Name === "" && LanguageSelected == "zh-TW"  &&
                                                                item.Street+(item.Street_No_Min === item.Street_No_Max? item.Street_No_Min: item.Street_No_Min + "-"+ item.Street_No_Max) + "號"
                                                            }

                                                            {item.Building_Name === "" && LanguageSelected == "zh-CN"  &&
                                                                item.Street+(item.Street_No_Min === item.Street_No_Max? item.Street_No_Min: item.Street_No_Min + "-"+ item.Street_No_Max) + "号"
                                                            }

                                                            {item.Building_Name === "" && LanguageSelected == "en-US"  &&
                                                               "No." +(item.Street_No_Min === item.Street_No_Max? item.Street_No_Min: item.Street_No_Min + "-"+ item.Street_No_Max) +" "+ item.Street
                                                            }

                                                            </span></td>
                                                            <td className={(index & 1) ? Resultcss.resultDistrict : Resultcss.resultDistrict}><span style={{ 'cursor': 'pointer' }} tabindex="0"  onClick={() => navigate('/info', { state: { search: "Single", building_id: item.building_id } })} onKeyDown={(event) => { if (event.key === "Enter") { navigate('/info', { state: { search: "Single", building_id: item.building_id } }) } }} >
                                                                {item.Address.split("|")[0]}</span>
                                                            </td>
                                                            <td className={(index & 1) ? Resultcss.resultOwner : Resultcss.resultOwner} >{item.OC.split("|").map(A => <p>{A}</p>)}{item.OComm.split("|").map(A => <p>{A}</p>)}</td>
                                                        </tr>
                                                    )
                                                    }
                                                </tbody>
                                            </table>
                                        )}
                                        {window.innerWidth < 700 && ResponseData && ResponseData.length > 0 && ResponseData.map((item, index) =>
                                            <table className={Resultcss.table} aria-label="Building info table">
                                                <tbody>
                                                    <tr key={item.building_id}>
                                                        <td className={Resultcss.Name} tabindex="0" aria-label="Building name">{t('Building_Name')}</td>
                                                        <td className={(index & 1) ? Resultcss.resultName : Resultcss.resultName} ><span style={{ 'cursor': 'pointer' }} tabindex="0" onClick={() => navigate('/info', { state: { search: "Single", building_id: item.building_id } })} onKeyDown={(event) => { if (event.key === "Enter") { navigate('/info', { state: { search: "Single", building_id: item.building_id } }); } }}  >
                                                           
                                                            {item.Building_Name.length > 0 &&
                                                                item.Building_Name
                                                            }
                                                            {item.Building_Name === "" && LanguageSelected == "zh-TW"  &&
                                                                item.Street+(item.Street_No_Min === item.Street_No_Max? item.Street_No_Min: item.Street_No_Min + "-"+ item.Street_No_Max) + "號"
                                                            }

                                                            {item.Building_Name === "" && LanguageSelected == "zh-CN"  &&
                                                                item.Street+(item.Street_No_Min === item.Street_No_Max? item.Street_No_Min: item.Street_No_Min + "-"+ item.Street_No_Max) + "号"
                                                            }

                                                            {item.Building_Name === "" && LanguageSelected == "en-US"  &&
                                                               "No." +(item.Street_No_Min === item.Street_No_Max? item.Street_No_Min: item.Street_No_Min + "-"+ item.Street_No_Max) +" "+ item.Street
                                                            }
                                                        </span></td>
                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr key={item.building_id}>
                                                        <td className={Resultcss.District} tabindex="0" aria-label="Address">{t('Address')}</td>
                                                        <td className={(index & 1) ? Resultcss.resultDistrict : Resultcss.resultDistrict} > <span style={{ 'cursor': 'pointer' }} tabindex="0" onClick={() => navigate('/info', { state: { search: "Single", building_id: item.building_id } })} onKeyDown={(event) => { if (event.key === "Enter") { navigate('/info', { state: { search: "Single", building_id: item.building_id } }); } }} >
                                                            {item.Address.split("|")[0]}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr key={item.building_id}>
                                                        <td className={Resultcss.Owner} aria-label="Result_OC">{t('Result_OC')}</td>
                                                        <td className={(index & 1) ? Resultcss.resultOwner : Resultcss.resultOwner}>{item.OC.split("|").map(A => <p>{A}</p>)}{item.OComm.split("|").map(A => <p>{A}</p>)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        )}
                                        {window.innerWidth >= 700 &&
                                            <div className={Resultcss.Pagination}>
                                                <Pagination lastItem={"⟩⟩"} firstItem={"⟨⟨"} boundaryRange={0} ellipsisItem={null} defaultActivePage={SearchPage} totalPages={TotalPage} onPageChange={handlePaginationChange} />
                                            </div>
                                        }

                                        {window.innerWidth < 700 &&
                                            <div className={Resultcss.Pagination} >
                                                <Pagination boundaryRange={0} ellipsisItem={null} size='mini' firstItem={null} lastItem={null} defaultActivePage={SearchPage} totalPages={TotalPage} onPageChange={handlePaginationChange} />
                                            </div>
                                        }
                                        <div className={Resultcss.Pagination}>
                                            {TotalPage === 0 && (
                                                <span>1/1 | </span>
                                            )
                                                }
                                            {TotalPage > 0 && (
                                                <span>{SearchPage}/{TotalPage} | </span>
                                            )
                                            }

                                            {SearchPage === 1 && TotalRecord > 0 && SearchPageSize > TotalRecord && (LanguageSelected == "zh-TW") && (
                                                <span>
                                                    總共 {TotalRecord} 項記錄中的 1 - {TotalRecord} 項
                                                </span>
                                            )
                                            }
                                            {SearchPage === 1 && TotalRecord > 0 && SearchPageSize > TotalRecord && (LanguageSelected == "zh-CN") && (
                                                <span>
                                                    总共 {TotalRecord} 项记录中的 1 - {TotalRecord} 项
                                                </span>
                                            )
                                            }
                                            {SearchPage === 1 && TotalRecord > 0 && SearchPageSize > TotalRecord && (LanguageSelected == "en-US") && (
                                                <span>
                                                    1 - {TotalRecord} of total {TotalRecord} record(s)
                                                </span>
                                            )
                                            }

                                            {SearchPage === 1 && TotalRecord > 0 && SearchPageSize < TotalRecord && (LanguageSelected == "zh-TW") && (
                                                <span>
                                                    總共 {TotalRecord} 項記錄中的 1 - {SearchPageSize} 項
                                                </span>
                                            )
                                            }
                                            {SearchPage === 1 && TotalRecord > 0 && SearchPageSize < TotalRecord && (LanguageSelected == "zh-CN") && (
                                                <span>
                                                    总共 {TotalRecord} 项记录中的 1 - {TotalRecord} 项
                                                </span>
                                            )
                                            }
                                            {SearchPage === 1 && TotalRecord > 0 && SearchPageSize < TotalRecord && (LanguageSelected == "en-US") && (
                                                <span>
                                                    1 - {SearchPageSize} of total {TotalRecord} record(s)
                                                </span>
                                            )
                                            }

                                            {SearchPage > 1 && SearchPageSize * SearchPage < TotalRecord && (LanguageSelected == "zh-TW") && (
                                                <span>
                                                    總共 {TotalRecord} 項記錄中的 {SearchPageSize * (SearchPage - 1) + 1} - {SearchPageSize * SearchPage} 項
                                                </span>
                                            )
                                            }
                                            {SearchPage > 1 && SearchPageSize * SearchPage < TotalRecord && (LanguageSelected == "zh-CN") && (
                                                <span>
                                                    总共 {TotalRecord} 项记录中的 {SearchPageSize * (SearchPage - 1) + 1} - {SearchPageSize * SearchPage} 项
                                                </span>
                                            )
                                            }
                                            {SearchPage > 1 && SearchPageSize * SearchPage < TotalRecord && (LanguageSelected == "en-US") && (
                                                <span>
                                                    {SearchPageSize * (SearchPage - 1) + 1} - {SearchPageSize * SearchPage} of total {TotalRecord} record(s)
                                                </span>
                                            )
                                            }

                                            {SearchPage > 1 && SearchPageSize * SearchPage >= TotalRecord && TotalRecord > 0 && (LanguageSelected == "zh-TW") && (
                                                <span>
                                                    總共 {TotalRecord} 項記錄中的 {SearchPageSize * (SearchPage - 1) + 1} - {TotalRecord} 項
                                                </span>
                                            )
                                            }
                                            {SearchPage > 1 && SearchPageSize * SearchPage >= TotalRecord && TotalRecord > 0 && (LanguageSelected == "zh-CN") && (
                                                <span>
                                                    总共 {TotalRecord} 项记录中的 {SearchPageSize * (SearchPage - 1) + 1} - {TotalRecord} 项
                                                </span>
                                            )
                                            }
                                            {SearchPage > 1 && SearchPageSize * SearchPage >= TotalRecord && TotalRecord > 0 && (LanguageSelected == "en-US") && (
                                                <span>
                                                    {SearchPageSize * (SearchPage - 1) + 1} - {TotalRecord} of total {TotalRecord} record(s)
                                                </span>
                                            )
                                            }

                                            {TotalRecord === 0 && (LanguageSelected == "zh-TW") && (
                                                <span>
                                                    總共 0 項記錄中的 0 - 0 項
                                                </span>
                                            )
                                            }
                                            {TotalRecord === 0 && (LanguageSelected == "zh-CN") && (
                                                <span>
                                                    总共 0 项记录中的 0 - 0 项
                                                </span>
                                            )
                                            }
                                            {TotalRecord === 0 && (LanguageSelected == "en-US") && (
                                                <span>
                                                    0 - 0 of total 0 record(s)
                                                </span>
                                            )
                                            }
                                            <select style={{ "marginLeft": "15px" }} onChange={(event) => CallDB(SearchKey, 1, event.target.value, SortKey, SortDirection)} aria-label="Display count">
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                                <option value="25">25</option>
                                            </select>

                                        </div>
                                    </div>
                                    </Suspense>
                                </div>
                                <div className={Resultcss.footer}>
                                    <div className={Resultcss.footerLeftLabel} tabindex="0" ><a href="https://www.w3.org/WAI/WCAG2AA-Conformance"
                                        title="Explanation of WCAG 2 Level AA conformance">
                                        <img height="32" width="88"
                                            src="https://www.w3.org/WAI/WCAG21/wcag2.1AA-v"
                                            alt="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1" />
                                    </a></div>
                                    <div style={{ 'cursor': 'pointer' }} className={Resultcss.footerLeftLabel} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/notice') } }} onClick={() => navigate('/notice')} >{t('Notice')}</div>
                                    <div style={{ 'cursor': 'pointer' }} className={Resultcss.footerLeftLabel} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/policy') } }} onClick={() => navigate('/policy')} >{t('Policy')}</div>
                                    <div className={Resultcss.footerRightLabel} tabindex="0" >{t('Copyright_f')} {(new Date().getFullYear())} {t('Copyright_b')}</div>
                                </div>
                            </div>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Grid.Column>

            </div>
        </>
    )

}
export default Result;