
import React, { useState, useEffect } from 'react';

const HealthCheck = () =>{

    return (
        <>
            <p>Health Check is OK</p>
        </>
    );
}

export default HealthCheck;