import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import en from './locales/en/translation.json';
import cn from './locales/zh-cn/translation.json';
import tw from './locales/zh-tw/translation.json';

const resources = {
    'en-US': {
        translation: en,
    },
    'zh-CN': {
        translation: cn,
    },
    'zh-TW': {
        translation: tw,
    }
};


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    resources,
    fallbackLng: 'en-US',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;