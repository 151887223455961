import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { useTranslation, Trans } from 'react-i18next';
import './i18n';
import Initcss from './init.module.css';
import { List, Icon, Grid, Menu, Sidebar } from 'semantic-ui-react';

const Init = () => {
    const { t, i18n } = useTranslation();
    const [LanguageSelected, setLanguageSelected] = useState("en-US");
    const [Section, setSection] = useState("");
    const [Open, setOpen] = useState(false);
    const navigate = useNavigate();


    const dialogRef = useRef();

    useEffect(() => {
        document.title = t('Page_Title');
        // Call the showModal function on the dialog element
        // dialogRef.current.showModal();
        dialogRef.current.show();
        console.log("i18n:" + i18n.language);
        if (i18n.language === "zh-TW" || i18n.language === "zh-CN" || i18n.language === "en-US") {
            changeLanguage(i18n.language);
            setLanguageSelected(i18n.language);
            console.log("No Change");
        }
        else {
            changeLanguage("en-US");
            setLanguageSelected("en-US");
            console.log("Set en-US");
        }
        window.addEventListener('keydown', function (event) {
            const key = event.key; // const {key} = event; in ES6+
            if (key === "Escape") {
                setOpen(false)
            }

        });



    }, []);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        document.title = t('Page_Title');
    };

    const onChange = event => {
        event.preventDefault();
        setSection(document.getElementById('s1').value);
    }

    const onClick = event => {
        event.preventDefault();
        if (Section === "") {
            navigate('/search', { state: { search: "Full" } })
        } else {
            navigate('/search', { state: { search: "Condition", District: Section } });
        }
    }


    return (
        <>
            <h1 className="hidden">{t('Page_Title')}</h1>
            <div>
                <Grid.Column>
                    <Sidebar.Pushable>
                        <Sidebar
                            as={Menu}
                            animation='overlay'
                            icon='labeled'

                            onHide={() => setOpen(false)}
                            vertical
                            visible={Open}
                            width='thin'

                        >
                            <Menu.Item as='a' >
                                <List divided relaxed size='big'>
                                    <List.Item><img src={require('./image/logo.png')} alt="HAD LOGO"></img></List.Item>
                                    <List.Item></List.Item>
                                    <List.Item onClick={() => { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") }} alt="zh-cn">简体</List.Item>
                                    <List.Item onClick={() => { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") }} alt="zh-tw">繁體</List.Item>
                                    <List.Item onClick={() => { changeLanguage("en-US"); setLanguageSelected("en-US") }} alt="English">English</List.Item>
                                </List>
                            </Menu.Item>
                        </Sidebar>
                        <Sidebar
                            as={List}
                            animation='overlay'
                            icon='labeled'
                            direction='right'
                            onHide={() => setOpen(false)}
                            vertical
                            visible={Open}
                            width='thin'
                        >
                            <List.Item divided relaxed size='massive' className={Initcss.closebutton} >
                                X
                            </List.Item>
                        </Sidebar>

                        <Sidebar.Pusher dimmed={Open}>
                            <div className={Initcss.container} >
                                <div className={Initcss.responsive} >
                                    <Icon size='big' name='bars' className={Initcss.responsiveLogo} onClick={() => setOpen(true)}> </Icon></div>
                                <div className={Initcss.title}>
                                    <div>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") } }} className={LanguageSelected === "zh-CN" ? Initcss.i18nSelected : Initcss.i18n} onClick={() => { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") }} alt="zh-cn">简体</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") } }} className={LanguageSelected === "zh-TW" ? Initcss.i18nSelected : Initcss.i18n} onClick={() => { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") }} alt="zh-tw">繁體</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("en-US"); setLanguageSelected("en-US") } }} className={LanguageSelected === "en-US" ? Initcss.i18nSelected : Initcss.i18n} onClick={() => { changeLanguage("en-US"); setLanguageSelected("en-US") }} alt="English">English</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/text') } }} className={Initcss.textsize} onClick={() => navigate('/text')}> {t('Text')}</span>
                                    </div>
                                </div>
                                <div className={Initcss.logo} >
                                    <img style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} src={require('./image/logo.png')} className={Initcss.HADLOGO} onClick={() => navigate('/')} alt="HAD LOGO"></img>
                                    <img style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} src={require('./image/title_' + LanguageSelected + '.png')} className={Initcss.HADLOGO_title} onClick={() => navigate('/')} alt="HAD LOGO"></img>
                                </div>
                                <div className={Initcss.menu} >
                                </div>
                                <div className={Initcss.main}>
                                    <form id="homeForm" name="homeForm" className={Initcss.homeform}>
                                        <div className={Initcss.selection} style={{ 'cursor': 'pointer' }} >
                                            <label style={{ 'cursor': 'pointer' }} className={Initcss.selectLabel}>{t('District')}  </label>
                                            <select className={Initcss.selector} id="s1" tabIndex="0" name="section" onChange={onChange} aria-label="Selector">
                                                <option value="">{t('Select_Section')}</option>
                                                <option value="CENTRAL_AND_WESTERN">{t('CENTRAL_AND_WESTERN')}</option>
                                                <option value="WAN_CHAI">{t('WAN_CHAI')}</option>
                                                <option value="EASTERN">{t('EASTERN')}</option>
                                                <option value="SOUTHERN">{t('SOUTHERN')}</option>
                                                <option value="YAU_TSIM_MONG">{t('YAU_TSIM_MONG')}</option>
                                                <option value="SHAM_SHUI_PO">{t('SHAM_SHUI_PO')}</option>
                                                <option value="KOWLOON_CITY">{t('KOWLOON_CITY')}</option>
                                                <option value="WONG_TAI_SIN">{t('WONG_TAI_SIN')}</option>
                                                <option value="KWUN_TONG">{t('KWUN_TONG')}</option>
                                                <option value="KWAI_TSING">{t('KWAI_TSING')}</option>
                                                <option value="TSUEN_WAN">{t('TSUEN_WAN')}</option>
                                                <option value="TUEN_MUN">{t('TUEN_MUN')}</option>
                                                <option value="YUEN_LONG">{t('YUEN_LONG')}</option>
                                                <option value="NORTH">{t('NORTH')}</option>
                                                <option value="TAI_PO">{t('TAI_PO')}</option>
                                                <option value="SHA_TIN">{t('SHA_TIN')}</option>
                                                <option value="SAI_KUNG">{t('SAI_KUNG')}</option>
                                                <option value="ISLANDS">{t('ISLANDS')}</option>
                                            </select>
                                            <button onClick={onClick} className={Initcss.selection_button}> {t('District_Search')}</button>
                                        </div>
                                        <div style={{ 'cursor': 'pointer' }} className={Initcss.selection} onClick={() => navigate('/search', { state: { search: "None" } })}><label style={{ 'cursor': 'pointer' }} className={Initcss.selectLabel} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/search', { state: { search: "None" } }) } }} onClick={() => navigate('/search', { state: { search: "None" } })} >{t('Search')}</label> { /* Use state to pass parameter in local*/}
                                        </div>
                                        <div style={{ 'cursor': 'pointer' }} className={Initcss.selection} onClick={() => navigate('/search', { state: { search: "Full" } })}><label style={{ 'cursor': 'pointer' }} className={Initcss.selectLabel} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/search', { state: { search: "Full" } }) } }} onClick={() => navigate('/search', { state: { search: "Full" } })} >{t('ListAll')}</label>
                                        </div>
                                        <div style={{ 'cursor': 'pointer' }} className={Initcss.selection} onClick={() => navigate('/help')}><label style={{ 'cursor': 'pointer' }} className={Initcss.selectLabel} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/help') } }} onClick={() => navigate('/help')} >{t('Help')}</label>
                                        </div>
                                    </form>
                                </div>
                                <div className={Initcss.footer}>
                                    <div className={Initcss.footerLeftLabel} tabindex="0" ><a href="https://www.w3.org/WAI/WCAG2AA-Conformance"
                                        title="Explanation of WCAG 2 Level AA conformance">
                                        <img height="32" width="88"
                                            src="https://www.w3.org/WAI/WCAG21/wcag2.1AA-v"
                                            alt="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1" />
                                    </a></div>
                                    <div style={{ 'cursor': 'pointer' }} className={Initcss.footerLeftLabel} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/notice') } }} onClick={() => navigate('/notice')} >{t('Notice')}</div>
                                    <div style={{ 'cursor': 'pointer' }} className={Initcss.footerLeftLabel} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/policy') } }} onClick={() => navigate('/policy')} >{t('Policy')}</div>
                                    <div className={Initcss.footerRightLabel} tabindex="0" >{t('Copyright_f')} {(new Date().getFullYear())} {t('Copyright_b')}</div>
                                </div>
                            </div>

                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Grid.Column>

            </div>

            <dialog id="dialog" className={Initcss.dialog} ref={dialogRef} aria-label="dialog" >
                <header className="dialog">{t('Popup_header')}</header>
                <Trans><p className="dialog" >{t('Popup_body')}</p></Trans>
                <form method="dialog">
                    <button className={Initcss.dialogButton} aria-label="disclaimer close button">{t('Popup_button')}</button>
                </form>
            </dialog>
        </>
    )
}

export default Init;

