import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import Collapsible from 'react-collapsible';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Searchcss from './Search.module.css';
import { List, Icon, Grid, Menu, Sidebar } from 'semantic-ui-react';


const schema = yup
    .object()
    .shape({
        District: yup.string().nullable(),
        Building_Name: yup.string().nullable(),
        Estate: yup.string().nullable(),
        Street: yup.string().nullable(),
        Village: yup.string().nullable(),
        StreetNo: yup.string().nullable(),
        Storeys: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        Units: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        Yearfrom: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        Yearto: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        OC: yup.bool(),
        OComm: yup.bool(),
        PMC: yup.bool(),
        Others: yup.bool(),
        Without: yup.bool()

    })
    .required();

function removeEmptyFields(data) {
    Object.keys(data).forEach(key => {
        if (data[key] === '' || data[key] == null || data[key] === false) {
            delete data[key];
        }
    });
}

const Search = () => {
    const navigate = useNavigate();
    const params = useLocation();
    const [Open, setOpen] = useState(false);
    const [isTrigger, setTrigger] = useState(true);
    const { t, i18n } = useTranslation();

    const [LanguageSelected, setLanguageSelected] = useState(i18n.language);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        document.title = t('Page_Title');
    };
    const {
        register,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            OC: false,
            OComm: false,
            PMC: false,
            Others: false,
            Without: false
        }
    });

    function check(e) {
        var target = e.target;
        console.log(target);
        if (target.id === "Without" && target.checked) {
            document.getElementById('OC').checked = false;
            document.getElementById('OComm').checked = false;
            document.getElementById('PMC').checked = false;
            document.getElementById('Others').checked = false;
        } else if (target.checked && target.id !== "Without" && document.getElementById('Without').checked) {
            document.getElementById('Without').checked = false;
        }
        //console.log("You selected " + target.id);
    }



    const onSubmit = (data, e) => {
        Object.assign(data, { "search": "Condition" });
        removeEmptyFields(data);
        console.log(data, e);
        navigate('/result', { state: data }, { replace: true })
    }
    const onError = (errors, e) => {
        console.log(errors)
    }


    useEffect(() => {
        document.title = t('Page_Title');
        if (params.state !== null && params.state.search !== "None") {

            navigate('/result', { state: params.state }, { replace: true })
        }

        window.addEventListener('keydown', function (event) {
            const key = event.key; // const {key} = event; in ES6+
            if (key === "Escape") {
                setOpen(false)
            }

        });

    }, []);

    const DistrictVal = ["", "CENTRAL_AND_WESTERN", "WAN_CHAI", "EASTERN", "SOUTHERN", "YAU_TSIM_MONG", "SHAM_SHUI_PO", "KOWLOON_CITY", "WONG_TAI_SIN", "KWUN_TONG", "KWAI_TSING", "TSUEN_WAN", "TUEN_MUN", "YUEN_LONG", "NORTH", "TAI_PO", "SHA_TIN", "SAI_KUNG", "ISLANDS"];

    return (
        <>
            <h1 className="hidden">{t('Page_Title')}</h1>
            <div >
                <Grid.Column>
                    <Sidebar.Pushable>
                        <Sidebar
                            as={Menu}
                            animation='overlay'
                            icon='labeled'

                            onHide={() => setOpen(false)}
                            vertical
                            visible={Open}
                            width='thin'
                        >
                            <Menu.Item as='a' >
                                <List divided relaxed size='big'>
                                    <List.Item><img src={require('./image/logo.png')} alt="HAD LOGO"></img></List.Item>
                                    <List.Item onClick={() => navigate('/')}> {t('Home')} </List.Item>
                                    <List.Item onClick={() => navigate('/Search', { state: { search: "None" } })}>{t('Search')}</List.Item>
                                    <List.Item onClick={() => navigate('/result', { state: { search: "Full" } })}>{t('ListAll')}</List.Item>
                                    <List.Item onClick={() => navigate('/help')}>{t('Help')}</List.Item>
                                    <List.Item></List.Item>
                                    <List.Item onClick={() => { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") }} alt="zh-cn">简体</List.Item>
                                    <List.Item onClick={() => { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") }} alt="zh-tw">繁體</List.Item>
                                    <List.Item onClick={() => { changeLanguage("en-US"); setLanguageSelected("en-US") }} alt="English">English</List.Item>
                                </List>
                            </Menu.Item>
                        </Sidebar>
                        <Sidebar
                            as={List}
                            animation='overlay'
                            icon='labeled'
                            direction='right'
                            onHide={() => setOpen(false)}
                            vertical
                            visible={Open}
                            width='thin'
                        >
                            <List.Item divided relaxed size='massive' className={Searchcss.closebutton} >
                                X
                            </List.Item>
                        </Sidebar>

                        <Sidebar.Pusher dimmed={Open}>
                            <div className={Searchcss.container} >
                                <div className={Searchcss.responsive}><Icon size='big' name='bars' className={Searchcss.responsiveLogo} onClick={() => setOpen(true)}> </Icon></div>
                                <div className={Searchcss.title}>
                                    <div>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") } }} className={LanguageSelected === "zh-CN" ? Searchcss.i18nSelected : Searchcss.i18n} onClick={() => { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") }} alt="zh-cn">简体</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") } }} className={LanguageSelected === "zh-TW" ? Searchcss.i18nSelected : Searchcss.i18n} onClick={() => { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") }} alt="zh-tw">繁體</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("en-US"); setLanguageSelected("en-US") } }} className={LanguageSelected === "en-US" ? Searchcss.i18nSelected : Searchcss.i18n} onClick={() => { changeLanguage("en-US"); setLanguageSelected("en-US") }} alt="English">English</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/text') } }} className={Searchcss.textsize} onClick={() => navigate('/text')}> {t('Text')}</span>
                                    </div>
                                </div>
                                <div className={Searchcss.logo} >
                                    <img style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} src={require('./image/logo.png')} className={Searchcss.HADLOGO} onClick={() => navigate('/')} alt="HAD LOGO"></img>
                                    <img style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} src={require('./image/title_' + i18n.language + '.png')} className={Searchcss.HADLOGO_title} onClick={() => navigate('/')} alt="HAD LOGO"></img>
                                </div>
                                <div className={Searchcss.menu}>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} onClick={() => navigate('/')} ><Icon name='home' />{t('Home')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/Search', { state: { search: "None" } }) } }} onClick={() => navigate('/Search', { state: { search: "None" } })} ><Icon name='search' />{t('Search')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/result', { state: { search: "Full" } }) } }} onClick={() => navigate('/result', { state: { search: "Full" } })} ><Icon name='list' />{t('ListAll')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/help') } }} onClick={() => navigate('/help')} ><Icon name='question' />{t('Help')}</label>
                                </div>
                                <div className={Searchcss.main}>
                                    <div className={Searchcss.collapseItem}>
                                        <div className={Searchcss.collapseTrigger} onClick={() => setTrigger(!isTrigger)} >{t('Search')}</div>
                                        <Collapsible trigger="" open={isTrigger} triggerElementProps={{ 'aria-label': 'Collapsible' }} >
                                            <form onSubmit={handleSubmit(onSubmit, onError)} className={Searchcss.form} aria-label="Building Search form">
                                                <div className={Searchcss.box}>
                                                    <div className={Searchcss.label}> <label>{t('District')}</label> </div>
                                                    <div >  <select className={Searchcss.selector} name="section" {...register('District')} aria-label="District selector">
                                                        {DistrictVal.map((District) =>
                                                            <option value={District} key={District}>{t(District || 'All_District')}</option>
                                                        )}
                                                    </select> </div>
                                                </div>
                                                <div className={Searchcss.box}>
                                                    <div className={Searchcss.label}> <label>{t('Building_Name')}</label> </div>
                                                    <div> <input className={Searchcss.textbox} {...register('Building_Name')} aria-label="Building Name" /> </div>
                                                </div>
                                                <div className={Searchcss.box}>
                                                    <div className={Searchcss.label}> <label>{t('Estate')}</label> </div>
                                                    <div> <input className={Searchcss.textbox} {...register('Estate')} aria-label="Estate Input" /> </div>
                                                </div>
                                                <div className={Searchcss.box}>
                                                    <div className={Searchcss.label}> <label>{t('Street')}</label></div>
                                                    <div> <input className={Searchcss.textbox} {...register('Street')} aria-label="Street Input" /> </div>
                                                </div>
                                                <div className={Searchcss.box}>
                                                    <div className={Searchcss.label}> <label>{t('Village')}</label> </div>
                                                    <div> <input className={Searchcss.textbox} {...register('Village')} aria-label="Village Input" /> </div>
                                                </div>
                                                <div className={Searchcss.box}>
                                                    <div className={Searchcss.label}> <label>{t('StreetNo')}</label> </div>
                                                    <div> <input className={Searchcss.textbox} {...register('StreetNo')} aria-label="Street No Input" /> </div>
                                                </div>
                                                <div className={Searchcss.box}>
                                                    <div className={Searchcss.label}> <label>{t('Storeys')}</label> </div>
                                                    <div> <input type="number" min="0" className={Searchcss.textbox} {...register('Storeys')} aria-label="Storeys Input" /> </div>
                                                </div>
                                                <div className={Searchcss.box}>
                                                    <div className={Searchcss.label}> <label>{t('Units')}</label> </div>
                                                    <div> <input type="number" min="0" className={Searchcss.textbox} {...register('Units')} aria-label="Units Input" /> </div>
                                                </div>
                                                <div className={Searchcss.box}>
                                                    <div className={Searchcss.label}> <label>{t('Yearfrom')}</label> </div>
                                                    <div> <input type="number" min="0" className={Searchcss.textbox} {...register('Yearfrom')} aria-label="Year From Input" /> </div>
                                                </div>
                                                <div className={Searchcss.box}>
                                                    <div className={Searchcss.label}> <label>{t('Yearto')}</label> </div>
                                                    <div> <input type="number" min="0" className={Searchcss.textbox} {...register('Yearto')} aria-label="Year To Input" /> </div>
                                                </div>

                                                <div className={Searchcss.fieldset}>
                                                    <label >{t('Organization_Type')}</label><br></br>
                                                    <div className={Searchcss.alignSet}>
                                                        <input {...register('OC')} type='checkbox' className={Searchcss.SearchCheckbox} onChange={check} id='OC' aria-label="OC checkbox" />&nbsp;
                                                        <label >{t('OC')}</label>
                                                    </div>
                                                    <div className={Searchcss.alignSet}>
                                                        <input {...register('OComm')} type='checkbox' className={Searchcss.SearchCheckbox} onChange={check} id='OComm' aria-label="OComm Checkbox" />&nbsp;
                                                        <label >{t('OComm')}</label>
                                                    </div>
                                                    <div className={Searchcss.alignSet}>
                                                        <input {...register('PMC')} type='checkbox' className={Searchcss.SearchCheckbox} onChange={check} id='PMC' aria-label="PMC Checkbox" />&nbsp;
                                                        <label >{t('PMC')}</label>
                                                    </div>
                                                    <div className={Searchcss.alignSet}>
                                                        <input {...register('Others')} type='checkbox' className={Searchcss.SearchCheckbox} onChange={check} id='Others' aria-label="OThers Checkbox" />&nbsp;
                                                        <label >{t('Others')}</label>
                                                    </div>
                                                    <div className={Searchcss.alignSet}>
                                                        <input {...register('Without')} type='checkbox' className={Searchcss.SearchCheckbox} onChange={check} id='Without' aria-label="Without Checkbox" />&nbsp;
                                                        <label >{t('Without')}</label>
                                                    </div>
                                                </div>

                                                <input type="submit" value={t('Submit')} aria-label="Submit" className={Searchcss.AllButton} />
                                                <input type="reset" value={t('Reset')} aria-label="Reset" className={Searchcss.AllButton} />
                                            </form>
                                        </Collapsible>
                                    </div>
                                </div>
                                <div className={Searchcss.footer}>
                                    <div className={Searchcss.footerLeftLabel} tabindex="0" ><a href="https://www.w3.org/WAI/WCAG2AA-Conformance"
                                        title="Explanation of WCAG 2 Level AA conformance">
                                        <img height="32" width="88"
                                            src="https://www.w3.org/WAI/WCAG21/wcag2.1AA-v"
                                            alt="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1" />
                                    </a></div>
                                    <div style={{ 'cursor': 'pointer' }} className={Searchcss.footerLeftLabel} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/notice') } }} onClick={() => navigate('/notice')} >{t('Notice')}</div>
                                    <div style={{ 'cursor': 'pointer' }} className={Searchcss.footerLeftLabel} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/policy') } }} onClick={() => navigate('/policy')} >{t('Policy')}</div>
                                    <div className={Searchcss.footerRightLabel} tabindex="0" >{t('Copyright_f')} {(new Date().getFullYear())} {t('Copyright_b')}</div>
                                </div>
                            </div>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Grid.Column>

            </div>
        </ >


    )
}

export default Search;

