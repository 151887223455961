import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Infocss from './Result_info.module.css';
import { List, Icon, Grid, Menu, Sidebar } from 'semantic-ui-react';

const Info = () => {
    const [responseData, setResponseData] = useState(null);
    const params = useLocation();
    const { t, i18n } = useTranslation();
    const [LanguageSelected, setLanguageSelected] = useState(i18n.language);
    const [Open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [SearchKey, setSearchKey] = useState(null);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        document.title = t('Page_Title');
        CallDB(SearchKey)
    };
    function CallDB(SearchKey) {
        try {
            var raw = JSON.stringify({
                "Case": SearchKey,
                "Language": i18n.language
            });
            console.log(raw);
            fetch('/public_backend/id/', {
                method: 'POST',
                body: raw,
                headers: {
                    'Content-type': 'text/plain; charset=utf-8',
                },
            })
                .then(async response => {
                    const data = await response.json();
                    console.log(data);
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;

                        return Promise.reject(error);
                    } else {
                        //only success request will store 
                        setSearchKey(SearchKey);
                        setResponseData(data);
                    }
                })
                .catch(error => console.error("ERROR:" + error));
        }
        catch (error) {
            console.error("Catched ERROR:" + error);
        }
        finally {
            console.log("Done!");
        };
    };

    useEffect(() => {
        document.title = t('Page_Title');
        if (params.state !== null) {
            console.log(params.state)
            CallDB(params.state)
        }

        window.addEventListener('keydown', function (event) {
            const key = event.key; // const {key} = event; in ES6+
            if (key === "Escape") {
                setOpen(false)
            }

        });

    }, []);


    return (
        <>
            <h1 className="hidden">{t('Page_Title')}</h1>
            <div>
                <Grid.Column>
                    <Sidebar.Pushable>
                        <Sidebar
                            as={Menu}
                            animation='overlay'
                            icon='labeled'

                            onHide={() => setOpen(false)}
                            vertical
                            visible={Open}
                            width='thin'
                        >
                            <Menu.Item as='a' >
                                <List divided relaxed size='big'>
                                    <List.Item><img src={require('./image/logo.png')} alt="HAD LOGO"></img></List.Item>
                                    <List.Item onClick={() => navigate('/')}> {t('Home')} </List.Item>
                                    <List.Item onClick={() => navigate('/Search', { state: { search: "None" } })}>{t('Search')}</List.Item>
                                    <List.Item onClick={() => navigate('/result', { state: { search: "Full" } })}>{t('ListAll')}</List.Item>
                                    <List.Item onClick={() => navigate('/help')}>{t('Help')}</List.Item>
                                    <List.Item></List.Item>
                                    <List.Item onClick={() => { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") }} alt="zh-cn">简体</List.Item>
                                    <List.Item onClick={() => { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") }} alt="zh-tw">繁體</List.Item>
                                    <List.Item onClick={() => { changeLanguage("en-US"); setLanguageSelected("en-US") }} alt="English">English</List.Item>
                                </List>
                            </Menu.Item>
                        </Sidebar>
                        <Sidebar
                            as={List}
                            animation='overlay'
                            icon='labeled'
                            direction='right'
                            onHide={() => setOpen(false)}
                            vertical
                            visible={Open}
                            width='thin'
                        >
                            <List.Item divided relaxed size='massive' className={Infocss.closebutton} >
                                X
                            </List.Item>
                        </Sidebar>

                        <Sidebar.Pusher dimmed={Open}>
                            <div className={Infocss.container} >
                                <div className={Infocss.responsive} ><Icon size='big' name='bars' className={Infocss.responsiveLogo} onClick={() => setOpen(true)}> </Icon></div>
                                <div className={Infocss.title}>
                                    <div>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") } }} className={LanguageSelected === "zh-CN" ? Infocss.i18nSelected : Infocss.i18n} onClick={() => { changeLanguage("zh-CN"); setLanguageSelected("zh-CN") }} alt="zh-cn">简体</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") } }} className={LanguageSelected === "zh-TW" ? Infocss.i18nSelected : Infocss.i18n} onClick={() => { changeLanguage("zh-TW"); setLanguageSelected("zh-TW") }} alt="zh-tw">繁體</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { changeLanguage("en-US"); setLanguageSelected("en-US") } }} className={LanguageSelected === "en-US" ? Infocss.i18nSelected : Infocss.i18n} onClick={() => { changeLanguage("en-US"); setLanguageSelected("en-US") }} alt="English">English</span>
                                        <span style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/text') } }} className={Infocss.textsize} onClick={() => navigate('/text')}> {t('Text')}</span>
                                    </div>
                                </div>
                                <div className={Infocss.logo} >
                                    <img style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} src={require('./image/logo.png')} className={Infocss.HADLOGO} onClick={() => navigate('/')} alt="HAD LOGO"></img>
                                    <img style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} src={require('./image/title_' + i18n.language + '.png')} className={Infocss.HADLOGO_title} onClick={() => navigate('/')} alt="HAD LOGO"></img>
                                </div>
                                <div className={Infocss.menu}>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/') } }} onClick={() => navigate('/')} ><Icon name='home' />{t('Home')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/Search', { state: { search: "None" } }) } }} onClick={() => navigate('/Search', { state: { search: "None" } })} ><Icon name='search' />{t('Search')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/result', { state: { search: "Full" } }) } }} onClick={() => navigate('/result', { state: { search: "Full" } })} ><Icon name='list' />{t('ListAll')}</label>
                                    <label style={{ 'cursor': 'pointer' }} tabIndex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/help') } }} onClick={() => navigate('/help')} ><Icon name='question' />{t('Help')}</label>
                                </div>
                                <div className={Infocss.main}>

                                    {responseData && responseData.length > 0 && responseData.map(item =>
                                        <>
                                            <div className={Infocss.form}>
                                                <div className={Infocss.Information}>{t('Building_Info')}</div>
                                                <div className={Infocss.content}>
                                                    <div className={Infocss.box}>
                                                        <div className={Infocss.label}> <label>{t('District')}</label> </div>
                                                        <div className={Infocss.resulttextbox} > {item.District} </div>
                                                    </div>
                                                    <div className={Infocss.box}>
                                                        <div className={Infocss.label}> <label>{t('Building_Name')}</label> </div>
                                                        <div className={Infocss.resulttextbox} > {item.Building_Name} </div>
                                                    </div>
                                                    <div className={Infocss.box}>
                                                        <div className={Infocss.label}> <label>{t('Estate')}</label> </div>
                                                        <div className={Infocss.resulttextbox} > {item.Estate} </div>
                                                    </div>
                                                    <div className={Infocss.box}>
                                                        <div className={Infocss.label}> <label>{t('Storeys')}</label> </div>
                                                        <div className={Infocss.resulttextbox} > {item.Storeys} </div>
                                                    </div>
                                                    <div className={Infocss.box}>
                                                        <div className={Infocss.label}> <label>{t('Basement')}</label></div>
                                                        <div className={Infocss.resulttextbox} > {item.Basement} </div>
                                                    </div>
                                                    <div className={Infocss.box}>
                                                        <div className={Infocss.label}> <label>{t('Units')}</label> </div>
                                                        <div className={Infocss.resulttextbox} > {item.Units} </div>
                                                    </div>
                                                    <div className={Infocss.box}>
                                                        <div className={Infocss.label}> <label>{t('YearBuilt')}</label> </div>
                                                        <div className={Infocss.resulttextbox} >{item.Year_Built}  </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={Infocss.form}>
                                                <div className={Infocss.Information}>{t('Address')}</div>
                                                <div className={Infocss.content}>
                                                    <div className={Infocss.AddressBox}>
                                                        {item.Address.length > 0 && item.Address.split("|").map(A =>
                                                            <div className={Infocss.AddressTextBox} > {A} </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={Infocss.form}>
                                                <div className={Infocss.Information}>{t('Building_Organization')}</div>
                                                <div className={Infocss.content}>
                                                    <table className={Infocss.InfoTable} aria-label="Organization_Type">
                                                        <tr>
                                                            <th className={Infocss.TitleTextBox} >{t('Organization_Type')}</th>
                                                            <th className={Infocss.TitleInfoTextBox} >{t('Building_Organization_Name')}</th>
                                                        </tr>
                                                        {item.OC.length > 0 && item.OC.split("|").map(A =>
                                                            <tr>
                                                                <td className={Infocss.TextBox} >{t('OC')}</td>
                                                                <td className={Infocss.InfoTextBox} >{A}</td>
                                                            </tr>
                                                        )}
                                                        {item.OComm.length > 0 && item.OComm.split("|").map(A =>
                                                            <tr>
                                                                <td className={Infocss.TextBox} >{t('OComm')}</td>
                                                                <td className={Infocss.InfoTextBox} >{A}</td>
                                                            </tr>
                                                        )}
                                                        {item.PMC.length > 0 && item.PMC.split("|").map(A =>
                                                            <tr>
                                                                <td className={Infocss.TextBox} >{t('PMC')}</td>
                                                                <td className={Infocss.InfoTextBox} >{A}</td>
                                                            </tr>
                                                        )}
                                                        {item.Others.length > 0 && item.Others.split("|").map(A =>
                                                            <tr>
                                                                <td className={Infocss.TextBox} >{t('Others')}</td>
                                                                <td className={Infocss.InfoTextBox} >{A}</td>
                                                            </tr>
                                                        )}
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    }

                                    <button className={Infocss.backpage} onKeyDown={(event) => { if (event.key === "Enter") { navigate(-1) } }} onClick={() => navigate(-1)} >{t('Back')}</button>
                                </div>
                                <div className={Infocss.footer}>
                                    <div className={Infocss.footerLeftLabel} tabindex="0" ><a href="https://www.w3.org/WAI/WCAG2AA-Conformance"
                                        title="Explanation of WCAG 2 Level AA conformance">
                                        <img height="32" width="88"
                                            src="https://www.w3.org/WAI/WCAG21/wcag2.1AA-v"
                                            alt="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1" />
                                    </a></div>
                                    <div style={{ 'cursor': 'pointer' }} className={Infocss.footerLeftLabel} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/notice') } }} onClick={() => navigate('/notice')} >{t('Notice')}</div>
                                    <div style={{ 'cursor': 'pointer' }} className={Infocss.footerLeftLabel} tabindex="0" onKeyDown={(event) => { if (event.key === "Enter") { navigate('/policy') } }} onClick={() => navigate('/policy')} >{t('Policy')}</div>
                                    <div className={Infocss.footerRightLabel} tabindex="0" >{t('Copyright_f')} {(new Date().getFullYear())} {t('Copyright_b')}</div>
                                </div>
                            </div>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Grid.Column>
            </div>
        </>
    )

}
export default Info;