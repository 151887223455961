import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Init from './Init';
import Search from './Search';
import Result from './Result';
import Info from './Result_info';
import Policy from './Policy';
import Notice from './Notice';
import Help from './Help';
import Text from './Text';
import Health from './HealthCheck';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename="/public">
        <Routes>
            <Route path="/" element={<Init />} />
            <Route path="search" element={<Search />} />
            <Route path="result" element={<Result />} />
            <Route path="info" element={<Info />} />
            <Route path="policy" element={<Policy />} />
            <Route path="notice" element={<Notice />} />
            <Route path="help" element={<Help />} />
            <Route path="healthcheck" element={<Health />} />
            <Route path="text" element={<Text />} />
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
